import Signal from 'libs/signals';
import $user from 'signals/User.signal';

export const $joinRequests = Signal({
  redirect: false,
});

export const init = async (history) => {
  const { id, user_type_id: userTypeId } = $user.value;

  $joinRequests.update({
    redirect: true,
  });

  if (id) {
    history.push(userTypeId === 1 ? '/founder/settings?tab=Team' : '/investor/settings?tab=Team');
  } else {
    history.push('/login');
  }
};
