import Fetcher from 'libs/fetcher';
import $user from 'signals/User.signal';
import registerUserback from 'libs/userback';
import { $verifyEmail } from 'components/views/VerifyEmail/VerifyEmail.helpers';
import { auth } from 'utils/firebase';
import displayNotification from 'utils/displayNotification';
import $roles from 'signals/Roles.signal';
import { $form } from 'signals/global.signals';

const fetcher = new Fetcher();

const determineSubscriptionStatus = (user) => {
  try {
    const subscriptions = user?.organization?.subscriptions?.sort((a, b) => a.current_period_end - b.current_period_end) ?? [];
    const activeSubscription = subscriptions.slice(-1)[0] ?? null;

    // Subscription statuses: basic, expired, active

    if (!activeSubscription || activeSubscription.status === 'tbd') return 'basic';

    if ((activeSubscription.current_period_end * 1000) <= Date.now()) {
      return 'expired';
    }

    return 'active';
  } catch (error) {
    return 'expired';
  }
};

const registerFirebaseAuthListener = () => auth.onAuthStateChanged(async (authUser) => {
  try {
    if (authUser === null) {
      $user.reset();
      $user.update({
        isLoading: false,
        isSignedIn: false,
      });
      return;
    }

    $user.update({
      authUser,
    });

    const getUserQuery = {
      path: '/users',
      query: {
        where: {
          uid: authUser?.uid,
        },
        include: {
          user_type: true,
          ethnicity: true,
          gender: true,
          age: true,
          org_invite: true,
          users_on_teams: {
            include: {
              team: true,
            },
          },
          organization: {
            include: {
              founder_org: {
                include: {
                  deals: {
                    where: {
                      is_public: true,
                    },
                    include: {
                      investors_on_founder_pipeline: true,
                    },
                  },
                },
              },
              subscriptions: true,
              investor_org: true,
              users: true,
              teams: true,
              addresses: true,
              org_invite: true,
            },
          },
        },
      },
    };

    const user = await fetcher.query(getUserQuery);

    const userType = user[0]?.user_type_id === 1 ? 'founder' : 'investor';

    const roleData = await fetcher.query({
      path: '/roles',
      query: {
        where: {
          type: userType,
        },
      },
    });

    $roles.update({ roles: roleData });

    if (user[0]) {
      const { verification_id: verifyId } = $verifyEmail.value;
      const { id: userId, onboarding_step: onboarding, is_verified: isVerified } = user[0];

      if (onboarding !== 'completed' && isVerified && !verifyId) {
        const onboardingData = await fetcher.query({
          path: '/verificationlinks',
          query: {
            where: {
              user_id: userId,
            },
          },
        });

        if (onboardingData[0]) {
          const { id, email, domain, type, company_email: companyEmail } = onboardingData[0];

          $verifyEmail.update({
            verification_id: id,
            email,
            companyEmail,
            domain,
            type,
          });
        }
      }

      user[0].accountStatus = determineSubscriptionStatus(user[0]);

      $user.update({
        ...user[0],
        userType: user[0]?.user_type?.description?.toLowerCase(),
        isSignedIn: true,
        isLoading: false,
      });

      $form.reset(); // fix undefined dashboard redirect & clear form data
    }
  } catch (e) {
    displayNotification({
      message: e.message,
    });
  }
});

export default {
  registerFirebaseAuthListener,
  registerUserback,
};
