/* eslint-disable no-param-reassign */
export const intlFormatMoney = ({ amount, currency = 'USD', notation = 'standard' } = {}) => {
  try {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency, currencyDisplay: 'narrowSymbol', notation }).format(amount);
  } catch (error) {
    return amount;
  }
};

export const formatMoney = (num, currency = '$') => {
  if (Number.isNaN(num)) {
    currency = 0;
  }
  return `${currency}${Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
};

export const formatMoneyNoDecimals = (num, currency = '$') => {
  const money = formatMoney(num, currency);
  const moneyArray = money.split('');
  moneyArray.splice(-3, 3);
  return moneyArray.join('');
};

export const formatMoneyShorthand = (num, currency = '$') => {
  const roundedNum = Math.round(Number(num));
  if (roundedNum >= 1000000) {
    const millions = roundedNum / 1000000;
    return `${currency}${millions} million`;
  }
  const thousands = roundedNum / 1000;
  return `${currency}${thousands} thousand`;
};

export const formatMoneyOneLetter = (num, currency = '$') => {
  if (!num) { return ''; }
  if (num < 1000) { return `${currency}${num}`; }
  // if (num >= 1000000) {
  //   const millions = (50000 * Math.round(Number(num) / 50000)) / 1000000;
  //   return `${currency}${millions}M`;
  // }
  const thousands = (1000 * Math.round(Number(num) / 1000)) / 1000;
  return `${currency}${thousands}k`;
};
